/*
//////////////////////////////////////////////////////////
BOOTSTRAP SASS VARIABLES
//////////////////////////////////////////////////////////
*/
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$pagination-color:                  var(--bs-dark);
$pagination-bg:                     var(--bs-light);
$pagination-border-radius:          var(--bs-border-radius);
$pagination-border-width:           var(--bs-border-width);
$pagination-border-color:           var(--bs-dark);

$pagination-focus-color:            var(--bs-dark);
$pagination-focus-bg:               var(--bs-light);
$pagination-focus-box-shadow:       0;
$pagination-focus-outline:          0;

$pagination-hover-color:            var(--bs-light);
$pagination-hover-bg:               var(--bs-dark);
$pagination-hover-border-color:     var(--bs-dark); // Todo in v6: remove this?

$pagination-active-color:           var(--bs-light);
$pagination-active-bg:              var(--bs-dark);
$pagination-active-border-color:    var(--bs-dark);

$pagination-disabled-color:         var(--bs-secondary-color);
$pagination-disabled-bg:            var(--bs-secondary-bg);
$pagination-disabled-border-color:  var(--bs-dark);

$pagination-border-radius-sm:       0;
$pagination-border-radius-lg:       0;

/*
//////////////////////////////////////////////////////////
BOOTSTRAP CSS VARIABLES
//////////////////////////////////////////////////////////
*/

@import "../node_modules/bootstrap/scss/bootstrap";
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';


/*
//////////////////////////////////////////////////////////
BREAKPOINTS
//////////////////////////////////////////////////////////
*/

@media (min-width: 1920px)
  {
  .container
    {
    display: block;
    margin: 0 auto;
    padding: 0;
    max-width: 1920px;
    }
  }

@media (min-width: 576px) and (max-width: 1920px)
  {
  .container
    {
    display: block;
    margin: 0 auto;
    padding: 0 1.0rem;
    max-width: 100%;
    }
  }

@media (min-width: 0px) and (max-width: 576px)
  {
  .container
    {
    display: block;
    margin: 0 auto;
    padding: 0 1.0rem;
    max-width: 100%;
    }
  }

/*
//////////////////////////////////////////////////////////
GLOBAL DEFAULTS - DOM ELEMENTS
//////////////////////////////////////////////////////////
*/

  *
    {
    box-sizing: border-box;
    }

  html
    {
    scroll-behavior: smooth;
    }

  body
    {
    display: block;
    margin: 0;
    padding: 0;
    min-width: 360px;
    max-width: 100%;
    background-color: var(--bs-light);
    color: var(--bs-dark);
    }

  svg
    {
    fill: currentColor;
    }

  table td, table th, .table td, .table td
    {
    background-color: transparent;
    vertical-align: middle;
    }

  h1, h2, h3, h4, h5, h6
    {
    display: block;
    margin: 0;
    padding: 0 0 0.3em 0;
    line-height: 1.15em;
    }

  select, textarea, input, .floating-label, .form-control, .form-select
    {
    border-width: 2px !important;
    border-color: var(--bs-warning);
    outline: 0 none !important;
    color: var(--bs-dark) !important;
    font: normal 1.0rem/1.0rem var(--bs-font-monospace) !important;
    }

  select:disabled, textarea:disabled, input:disabled
    {
    border-color: indigo !important;
    border-width: 2px !important;
    color: indigo !important;
    }

  .dropdown-menu
    {
    padding: 0;
    border: 3px var(--bs-light) solid !important;
    height: auto !important;
    max-height: 40.0rem !important;
    overflow-y: scroll !important;
    line-height: 1.3rem !important;
    }

  [contenteditable]
    {
    outline: 0px none transparent;
    margin: 0px;
    padding: 0.3em;
    border-bottom: 2px var(--bs-gray-500) dotted;
    background-color: transparent;
    }

  [contenteditable]:focus {background-color: rgba(var(--bs-warning-rgb), 0.3); border-bottom: 2px rgba(var(--bs-dark-rgb), 0.8) dotted; }

  .pointer
    {
    cursor: pointer;
    }

/*
//////////////////////////////////////////////////////////
PRINT - Source (modified): https://github.com/coliff/bootstrap-print-css/
//////////////////////////////////////////////////////////
*/

@media print
  {
  *, *::before, *::after
    {
    text-shadow: none !important;
    box-shadow: none !important;
    }

  a:not(.btn)
    {
    text-decoration: underline;
    }

  abbr[title]::after
    {
    content: " (" attr(title) ")";
    }

  pre
    {
    white-space: pre-wrap !important;
    }

  pre, blockquote
    {
    border: 1px solid #adb5bdff;
    page-break-inside: avoid;
    }

  tr, img
    {
    page-break-inside: avoid;
    }

  h1, h2, h3, h4, h5, h6, p, ul
    {
    orphans: 3;
    widows: 3;
    }

  h1, h2, h3, h4, h5, h6
    {
    page-break-after: avoid;
    }

  @page
    {
    size: a3 landscape;
    @bottom-right
      {
      content: "Page " counter(page) " of " counter(pages);
      }
    }

  body
    {
    min-width: 992px !important;
    width: 100% !important;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust: exact !important;
    }

  .container
    {
    min-width: 992px !important;
    width: 100% !important;
    }

  .badge
    {
    border: 1px solid #000000ff;
    }

  .page-break
    {
    page-break-after: always;
    page-break-inside: auto;
    }

  .table
    {
    border-collapse: collapse !important;
    }

  table, td, th, .table, .table td, .table th
    {
    background-color: #ffffffff !important;
    vertical-align: middle !important;
    }

  .table-bordered th, .table-bordered td
    {
    border: 1px solid #dee2e6ff !important;
    }

  .table-dark
    {
    color: inherit;
    }

  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody
    {
    border-color: #dee2e6ff;
    }
  }

/*
================================
BOOTSTRAP CSS VARIABLES
» https://getbootstrap.com/docs/5.3/customize/css-variables/
================================

:root,
[data-bs-theme=light]
  {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
  }

[data-bs-theme=dark]
  {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
  }

*/
