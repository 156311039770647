.confirmDialogOverlay
  {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  }

.confirmDialog
  {
  width: fit-content;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  }

